﻿body {
    color: $base-font-color;
    font-family: $base-font-family;
    font-weight: $base-font-weight;
    line-height: scale(2, unitless);
    font-size: 20px;
}

a, .link {
    color: $link-color;
    text-decoration: underline;

    &:hover {
        color: $link-hover-color;
    }
}
footer a:hover, .link:hover {
    color:$white;
    opacity:0.8;
}
.white-link {
    color: white;
    text-decoration: underline;

    &:hover {
        color: $soft-gray;
    }
}

.tight-font {
    margin-bottom: -6px;
}

h1, h2, h6,
.h1, .h2, .h6 {
    color: $heading-font-color;
    font-family: "freight-text-pro", sans-serif;
}

h4, .h4 {
    font-family: "Museo Sans", sans-serif;
    font-weight: 500;
}

h3, .h3 {
    font-family: "freight-text-pro", sans-serif;
    font-weight: 500;
}

h5, .h5 {
    font-family: "proxima-nova", sans-serif;
    font-weight: bold;
}

.ingress {
    font-size: 150%;
    font-weight: 300;
    line-height: 120%;

    @media #{$small-only} {
        font-size: 130% !important;
        line-height: 125% !important;
    }
}


.h1 {
    font-size: scale(5);

    @media #{$large-up} {
        font-size: scale(7);
        line-height: 1.2em;
    }

    @media #{$small-only} {
        font-size: scale(4);
        line-height: 1.1;
        margin-bottom: 13px;
    }
}

.h2 {
    font-size: scale(4);

    @media #{$large-up} {
        font-size: scale(6);
    }
        @media #{$small-only} {
        font-size: scale(3);
        line-height: 1.1em; 
    }
}

.h3 {
    font-size: scale(3);
    line-height: 1.1em;

    @media #{$large-up} {
        font-size: scale(5);
        line-height: 1.1em;
    }

    @media #{$small-only} {
        font-size: scale(3);
        line-height: 1.1em; 
    }
}

.h4 {
    font-size: scale(2);

    @media #{$large-up} {
        font-size: scale(4);
    }
}

.h5 {
    font-size: scale(1);

    @media #{$large-up} {
        font-size: scale(3);
    }
}

.h6 {
    font-size: 1em;
}

.h1.not-bold,
.h2.not-bold,
.h3.not-bold,
.h4.not-bold,
.h5.not-bold,
.h6.not-bold {
    font-weight: normal;
}
//.quiet { color: $quiet-color; }
//.loud  { color: $loud-color; }
.alternate-heading {
    color: $alternate-heading-color;
}

.no-link {
    text-decoration: none;
}

.lightblue {
    color: $link-color;
}

.underline {
    text-decoration: underline !important;
}

.italic {
    font-family: $italic-font-family;
}

.regular {
    font-family: $base-font-family;
}

.bold {
    font-weight: 700;
}

.right {
    text-align: right;
}

.larger {
    font-size: 130%;
}
.smaller {
    font-size: 85%;
}
.medium {
    font-size: 110%;
}

a.white.link:hover {
    opacity: 0.8;
}

.uppercase {
    text-transform: uppercase;
}

.center {
    text-align: center;
}

@media #{$small-only} {
    .s-center {
        text-align: center;
    }
}

@media #{$small-up} {
    .s-up-center {
        text-align: center;
    }
}

@media #{$medium-only} {
    .m-center {
        text-align: center;
    }
}

@media #{$medium-up} {
    .m-up-center {
        text-align: center;
    }
}

@media #{$large-up} {
    .l-center {
        text-align: center;
    }
}

@media #{$large-up} {
    .l-up-center {
        text-align: center;
    }
}

.s-up-center {
}

.m-center {
}

.m-up-center {
}

.l-center {
}

.l-up-center {
}



.unordered-list {
    list-style-type: disc;
}

.ordered-list {
    list-style: decimal;
}

.horizontal-list > * {
    display: inline-block;
    list-style: none;
}

.block-margins {
    margin: 1em 0;
}

.small-links {
    font-size: 0.85em;
}

b, strong, .strong {
    font-weight: bold;
}



.typography {
    i, em {
        font-style: italic;
    }

    b, strong, .strong {
        font-weight: bold;
    }

    h1, .h1 {
        margin: 0.5em 0;
    }

    h2, .h2 {
        margin: 1em 0 0.5em;
    }

    h3, .h3 {
        margin: 1em 0 0.5em;
    }

    h4, .h4 {
        margin: 1em 0 0.5em;
    }

    h5, .h5 {
        margin: 1em 0 0.5em;
    }

    h6, .h6 {
        margin: 1em 0 0.5em;
    }

    p, ul, ol, pre {
        
    }

    ul, ol {
        list-style-position: inside;
        padding-left: 1em;
    }

    ul {
        
    }

    ol {
        
    }

    pre, code {
        
    }
}