﻿body {
    @media only screen and (min-width: 1345px) {
        background: #E9E9E9;
    }

    @media #{$small-only} {
        font-size: 18px;
    }
}

.block {
    display: block;
} 

.center-block.column {
    margin: 0 auto;
    float: none;
}

.page-wrapper-inner { 
    background: $white;
    overflow: hidden;
}

.inner-wrapper {
    margin: 0 auto;
    float: none;
    width: 74.41059%;
    overflow: hidden;

    @media #{$small-only} {
        width: 100%;
    }
}

.no-margin {
    margin: 0 !important;
}

.img-fill-box img {
    width: 100%;
    display: block;
}

.padding {
    padding: $large-padding 0;

    @media #{$small-only} {
        padding: $medium-padding 0;
    }
}

.padding-top {
    padding-top: 6em;

    @media #{$small-only} {
        padding-top: $medium-padding;
    }
}

.extra-padding-top {
    padding-top: 9em;

    @media #{$small-only} {
        padding-top: $medium-padding;
    }
}

.medium-padding {
    padding: $medium-padding;

    @media #{$small-only} {
        padding: $small-padding;
    }
}

.large-margin-top {
    margin-top: $large-padding !important;
} 

.medium-margin {
    margin: $medium-padding;

    @media #{$small-only} {
        margin: $small-padding;
    }
}

.large-padding {
    padding: $large-padding;

    @media #{$small-only} {
        padding: $medium-padding $tiny-padding;
    }
}

.small-padding {
    padding: $small-padding;
}

.tiny-padding {
    padding: $tiny-padding;
}

.small-padding-bottom {
    padding-bottom: $small-padding;
}

.small-margin-bottom {
    margin-bottom: $small-padding;
}

.small-margin-top {
    margin-top: $small-padding;
}

.medium-padding-bottom {
    padding-bottom: $medium-padding;
}

.large-padding-bottom {
    padding-bottom: $large-padding;
}

.large-padding-top {
    padding-top: $large-padding;
}

.padding-bottom {
    padding-bottom: 6em;

    @media #{$small-only} {
        padding-bottom: 1em;
    }
}

.full-width {
    min-width: 100%;
}

section {
    position: relative;
}

.min-height {
    min-height: 800px;

    @media #{$medium-only} {
        min-height: 650px;
    }

    @media #{$small-only} {
        min-height: 500px;
    }
}

.quote-img {
    min-height: 300px;
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
}

.bottom-left-to-right-diagonal {
    &:after {
        top: auto;
        bottom: 56px;
    }
}

section::before, section::after {
    position: absolute;
    content: '';
    pointer-events: none;
}


.white-bg {
    background: $white;
}

.green-bg {
    background: $green;
    color: $black;
}



.floorplan-outer .image {
    border: 2px solid $soft-gray;
}

.blue-bg {
    background: $blue;
    color: #ffffff;

    h1, h2, h3, h4, h5, h6,
    .h1, .h2, .h3, .h4, .h5, .h6 {
        color: #ffffff;
    }
}

.red-bg {
    background: $red;
    color: #ffffff;

    h2, h3, h4, h5, h6,
    .h2, .h3, .h4, .h5, .h6 {
        color: #ffffff;
    }

    h1, .h1 {
        color: white;
    }
}

.transparent-white-bg {
    background: rgba(255, 255, 255, 0.8);
}

.dark-gray-bg {
    background: $dark-gray;
    color: #ffffff;

    h1, h2, h3, h4, h5, h6 {
        color: #ffffff;
    }
}


.white {
    color: $white !important;
}

.clear {
    clear: both;
}

.relative {
    position: relative;
}

.absolute {
    position: absolute;
}

.overflow {
    overflow: auto;
}

.align-center {
    text-align: center;
}
.main__img {
    margin-top:2em;
}
.top-image-wrapper {
    min-height: 350px;

    @media #{$large-up} {
        min-height: 600px;
        position: relative;
    }
}

.text-overlay-top {
    position: absolute;
    top: 35%;
    left: 5%;

    &h1, &.h1 {
        color: #fff;
        letter-spacing: 2px;
        background: rgba(56, 135, 121, 0.79);
        box-shadow: 0px 0px 108px 47px rgba(56, 135, 121, 0.85);
    }
}

.video-control {
    right: 20px;
    bottom: 20px;
    z-index: 999;
}

.video-wrapper {
    video, img {
        width: 100%;
        display: block;
    }
}

.video-wrapper-inner img {
    display: block;
}

.logo-wrapper-inner {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;

    .video-logo {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -80px;
        margin-top: -40PX;

        @media #{$small-only} {
            margin-left: -60px;
            margin-top: -30px;
        }

        img {
            width: 100%;
        }
    }
}

.logo-wrapper-inner {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;

    .picture-top-logo {
        position: absolute;
        top: 0;
        left: 50%;
        margin-left: -180px;
        margin-top: 0;
        background: white;

        @media #{$small-only} {
            margin-left: -80px;
        }

        @media #{$medium-only} {
            margin-left: -160px;
        }

        img {
            width: 100%;
            padding: 40px 20px 10px 20px;

            @media #{$large-up} {
                min-width: 270px;
                max-width: 100%;
            }

            @media #{$medium-only} {
                min-width: 200px;
                max-width: 100%;
            }

            @media #{$small-only} {
                min-width: 80px;
                max-width: 150px;
                padding: 20px 10px 10px 10px;
            }
        }
    }
}

.col {
    width: 100%;
    float: left;

    @media #{$large-up} {
        width: 49%; 
    }
}

.PFO {
    max-width: 670px;
    padding: 3em 5em;

    @media #{$small-only} {
        margin: 40px 30px !important;
        padding: 1em 1.5em;
            background-repeat: repeat-x;
    background-size: 100%;
    width: 100%;
    }
}

.cafe {
    max-width: 500px;
        background: url('../Svgs/red-pattern.svg');
    background-color: $red;
    background-repeat:repeat; 
    background-size: 250%;
    width: 100%;
}

.partner-logo {
    display: inline-block;
    padding-right: 30px;
}

.poster-img {
    display: block;
}

.mobile-logo {
    max-width: 120px;
}

video {
    max-width: 100%;
}

.fill-image {
    margin-top: 0;
    background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    position: relative;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}


.logo-img-wrapper {
    display: inline-block;
}

.product-page {
    .product__img {
        width: 100%;
        display: block;
    }
}


.gallery-outer {
    position: relative;
    overflow: hidden;
    display: block;
    text-decoration: none;
    margin-bottom: $small-margin;

    @media #{$large-up} {
        &:nth-child(3n) {
            margin-right: 0 !important; 
        }
    }

    @media #{$small-only} {
        margin: 0 auto;
        float: none;
        margin-bottom: $tiny-margin;
        width: 100%;
    }

    @media #{$medium-only} {
        margin-bottom: 2.35765%;
        width: 47%;
        &:nth-child(2n) {
            margin-right: 0;
        }
    }

    .img-wrapper {
    }
}

.floorplan-page .floorplan__img {
    width: 100%;
    display: block;
}

.gallery-inner {
    @media #{$medium-up} {
        position: absolute;
        bottom: 20px;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto !important;
    }
}

.gallery-inner {
    @media #{$medium-up} {
        transition: ease all 500ms;
        overflow: hidden;
        transform: translateY(200%);
    }

    .hidden {
        @media #{$small-only} {
            display: none;
        }
    }
}

.gallery-outer .gallery-inner {
    @media #{$medium-up} {
        transform: translateY(0);
    }
}



//FOOTER

.footer {
    /*background: url('../Svgs/blue-pattern.svg');*/
    background-color: $blue;
    background-repeat:repeat; 
    background-size: 100%;
    width: 100%;
}
#map {
    position: relative;
    padding-bottom: 35%; // This is the aspect ratio
    height: 550px;
    overflow: hidden;

    @media #{$small-only} {
        height: 330px;
    }
}

#map iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
}

.footer-icons {
    .footer-item {
        float: left;
        margin: 0;
        padding: 0;
        border-left: 2px solid $white;
        border-top: 2px solid $white;
        padding: 12px 0;
        width: 33.333%;
        min-height: 75px;

        @media #{$medium-up} {
            width: 12.5%;

            &:first-child {
                border-left: none;
            }
        }

        @media #{$small-only} {
            &:nth-child(3n) {
                border-right: 2px solid $white;
            }

            &:nth-child(6) {
                border-bottom: 2px solid $white;
            }

            &:last-child {
                border-right: 2px solid $white;
            }
        }
    }

    a.footer-item:hover img {
        opacity: 0.5;
    }

    img {
        display: block;
        width: 90%;
        margin: 0 auto;
        max-height: 40px;
    }
}

.events {
    @media #{$small-only} {
        padding-bottom: $medium-padding;
    }
}

.event-item {
    @media #{$small-only} {
        padding-bottom: $medium-padding;
    }
}

img {
    max-width: 100%;
}

#VideoPlayControl {
    cursor: pointer;

    svg {
        width: 35px;
        opacity: 0.7;
    }
}

#VideoPauseControl {
    cursor: pointer;

    svg {
        width: 35px;
        opacity: 0.7;
    }
}




.facebook-icon {
    content: "";
    display: block;
    width: 55px;
    height: 55px;
    margin: 0 auto;
    background-image: url("../Svgs/fb.svg");
    background-repeat: no-repeat;
    transition: all 0.5s ease;
    margin-top: $tiny-margin;

    &:hover {
        opacity: 0.4;
    }
}

.to-top {
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.3);
    width: 50px;
    height: 50px;
    padding: 2px 6px;
    margin: 0;
    color: #fff;
    opacity: 0.5;
    position: fixed;
    bottom: 10px;
    right: 10px;
    text-decoration: none;
    -moz-transition: opacity ease-in 0.2s;
    -o-transition: opacity ease-in 0.2s;
    -webkit-transition: opacity ease-in 0.2s;
    transition: opacity ease-in 0.2s;
    background-image: url('../Icons/arrow-up.svg');
    background-size: 30px 30px;
    background-position: center;
    background-repeat: no-repeat;
    text-indent: -9999px;
    z-index: 99;

    &:hover {
        opacity: 1;
        color: #FFF;
    }
}
